import { useEffect } from "react";
import { Box, Button, Grid, Typography, styled, useTheme, Link } from "@mui/material";
import { ReactComponent as Fee } from "../../../assets/images/Fee.svg";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchJournalById } from "../../../redux/slices/webJournal";
import { APCPoints } from "../../../helpers/constant";
import * as DOMPurify from "dompurify";
import htmlParser from "html-react-parser";

const StyledBox = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: "100%",
  // background: theme.palette.primary.main,
  borderRadius: theme.borderRadius[10],
  padding: "25px",
  boxShadow: theme.boxShadow.shadowThree,
  marginBottom: "10px",
  textAlign: "center",
}));

const JournalAPC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { journalId } = useParams();

  const { journalDetails, journalLoading } = useSelector(
    (state) => state.webJournalReducer
  );

  useEffect(() => {
    if (journalId) {
      dispatch(fetchJournalById({ journalCustomId: journalId }));
    }
  }, [journalId, dispatch]);

  const renderSubPoints = (subPoints) => {
    if (!subPoints) return null;
    
    return (
      <ul style={{ listStyle: "disc", paddingLeft: "20px", marginTop: "10px" }}>
        {subPoints.map((point, index) => {
          if (typeof point === "string") {
            return (
              <li key={index}>
                <Typography variant="body1">
                  {htmlParser(DOMPurify.sanitize(point))}
                </Typography>
              </li>
            );
          }
          return (
            <li key={index}>
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                {htmlParser(DOMPurify.sanitize(point.title))}
              </Typography>
              <ul style={{ listStyle: "circle", paddingLeft: "20px" }}>
                {point.points.map((subPoint, subIndex) => (
                  <li key={subIndex}>
                    <Typography variant="body1">
                      {htmlParser(DOMPurify.sanitize(subPoint))}
                    </Typography>
                  </li>
                ))}
              </ul>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <Box
      sx={{
        padding: "20px 40px",
        height: "100%",
        textAlign: "justify",
      }}
    >
      <Grid container justifyContent={"center"} alignItems={"center"} spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: 800,
              marginBottom: "20px",
            }}
          >
            Article Processing Charge (APC)
          </Typography>
          
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              marginBottom: "20px",
            }}
          >
            <strong>{journalDetails?.name}</strong> follows the principles of transparency and best practices in scholarly publishing, in line with guidelines set by COPE, DOAJ, OASPA, and WAME. The following details provide clear information on publication charges and related practices:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Fee style={{ maxWidth: "400px" }} />
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ mt: 4 }}>
        {APCPoints({journalDetails}).map((point, index) => (
          <Box key={index} sx={{ mb: 3 }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 600,
              }}
            >
              {`${index + 1}. ${point.title}`}
            </Typography>
            {point.description && (
              <Typography variant="body1" gutterBottom>
                {htmlParser(DOMPurify.sanitize(point.description))}
              </Typography>
            )}
            {renderSubPoints(point.subPoints)}
          </Box>
        ))}
      </Box>

      <Typography variant="body1" gutterBottom sx={{ mt: 3 }}>
        For more information on the submission process, APC, or fee waivers, please contact the editorial office at{" "}
        <Link 
          href="mailto:office@ifrdiscovery.org"
          sx={{ 
            color: theme.palette.info.main,
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline'
            }
          }}
        >
          office@ifrdiscovery.org
        </Link>
      </Typography>
      
      <Typography variant="body1" gutterBottom sx={{ mt: 2 }}>
        We remain committed to <strong>transparency, accessibility,</strong> and <strong>ethical practices</strong> in all aspects of our publishing process, ensuring a fair and inclusive environment for all authors.
      </Typography>
    </Box>
  );
};

export default JournalAPC;
