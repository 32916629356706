import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, styled } from "@mui/material";
import { useTheme } from "@emotion/react";
import articleWebsiteServices from "../../../services/article/website";
import { useParams } from "react-router-dom";
import * as DOMPurify from "dompurify";
import htmlParser from "html-react-parser";
import { saveAs } from "file-saver";
import moment from "moment";
import { CustomDialog, CustomMenu, Loader } from "../../../components";
import articleAnalysisWebsiteServices from "../../../services/articleAnalysis/website";
import { setReduxJournalDetails } from "../../../redux/slices/webJournal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CopyrightLogo from "../../../assets/images/CopyrightLogo.png";
import { Helmet } from "react-helmet-async";

const StyledBadgeBox = styled(Box, {
  shouldForwardProp: (prop) => ["needTopMargin"].indexOf(prop) === -1,
})(({ theme, needTopMargin = false }) => ({
  padding: "5px 10px",
  borderRadius: "30px",
  background: theme.palette.primary.main,
  marginBottom: "10px",
  textAlign: "center",
}));

const currentDate = new Date();

const formatReferences = (data) => {
  let resultStr = "";
  data.authorNames.forEach((authorName, authorNameIndex) => {
    let andSymbol = data.authorNames?.length === authorNameIndex + 1 ? "&" : "";
    if (resultStr.length > 0) {
      resultStr += `, ${andSymbol} ${authorName.surname}${
        authorName.shortName ? `, ${authorName.shortName}` : ""
      }`;
    } else {
      resultStr += `${authorName.surname}${
        authorName.shortName ? `, ${authorName.shortName}` : ""
      }`;
    }
  });

  if (data.year) {
    resultStr += ` (${data.year}).`;
  }

  if (data.articleTitle) {
    resultStr += ` ${data.articleTitle}.`;
  }

  if (data.journalName) {
    resultStr += ` ${data.journalName},`;
  }

  if (data.volume) {
    resultStr += ` ${data.volume}`;
  }

  if (data.issue) {
    resultStr += `(${data.issue}),`;
  }

  if (data.fPage) {
    resultStr += ` ${data.fPage}`;
  }

  if (data.lPage) {
    resultStr += `-${data.lPage}.`;
  }

  if (data.link) {
    resultStr += ` ${data.link}`;
  }

  // resultStr += ` (${data.year}). ${data.articleTitle}. ${data.journalName}, ${data.volume}(${data.issue}), ${data.fPage}-${data.lPage}. ${data.link}`;
  return resultStr;
};

const ArticleView = () => {
  const { articleId } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { journalDetails } = useSelector((state) => state.webJournalReducer);

  const [articleDetails, setArticleDetails] = useState({
    data: {},
    loading: false,
  });

  const [openCitation, setOpenCitation] = useState(false);
  const [openAuthorEmail, setOpenAuthorEmail] = useState({
    open: false,
    authorName: "",
    authorEmail: "",
  });

  useEffect(() => {
    const fetchArticle = async () => {
      setArticleDetails((prev) => ({
        ...prev,
        loading: true,
      }));
      const resArticle = await articleWebsiteServices.getArticleByIdWebsite({
        articleId,
      });
      if (resArticle?.data) {
        setArticleDetails((prev) => ({
          ...prev,
          data: resArticle.data,
          loading: false,
        }));
        if (!journalDetails) {
          dispatch(setReduxJournalDetails(resArticle.data.journal));
        }
      } else {
        setArticleDetails((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    };
    fetchArticle();
  }, [articleId]);

  const onClickDownload = (url, filename) => {
    saveAs(`${process.env.REACT_APP_FILE_BASE_URL}${url}`, filename);
    articleAnalysisWebsiteServices.updateArticleAnalysis({
      article: articleDetails?.data?._id,
      download: true,
    });
  };

  const getXMLData = async (articleId, xmlType, actionType) => {
    // const curr = "fetch-xml";
    // toast.loading(`fetching xml data...`, {
    //   toastId: curr,
    // });

    const resXML = await articleWebsiteServices.downloadArticleXML({
      articleId,
      xmlType,
    });

    if (resXML?.data) {
      // toast.update(curr, {
      //   render: "XML fetch successful.",
      //   type: "success",
      //   autoClose: 5000,
      // });
      let xmlBlob = new Blob([resXML?.data], {
        type: "application/xml",
      });

      if (xmlBlob && actionType === "download") {
        saveAs(xmlBlob, `${articleDetails?.data?.name}`);
        articleAnalysisWebsiteServices.updateArticleAnalysis({
          article: articleDetails?.data?._id,
          download: true,
        });
      } else if (xmlBlob && actionType === "view") {
        let url = URL.createObjectURL(xmlBlob);
        window.open(url);
        URL.revokeObjectURL(url);
      }
    } else {
      // toast.update(curr, {
      //   render: "XML fetch failed.",
      //   type: "error",
      //   autoClose: 5000,
      // });
    }
  };

  const xmlOptions = [
    {
      name: "View",
      onSelect: async () =>
        await getXMLData(articleDetails?.data?._id, "nml", "view"),
    },
    {
      name: "Download",
      onSelect: async () =>
        await getXMLData(articleDetails?.data?._id, "nml", "download"),
    },
  ];

  return (
    <Box
      sx={{
        padding: "20px",
        height: "100%",
        background: theme.palette.primary.litSurface,
      }}
    >
      <Helmet>
        <meta name="citation_doi" content={`${articleDetails?.data?.doi}`} />
      </Helmet>
      {articleDetails?.loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Loader />
        </Box>
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <Box
                sx={{
                  maxWidth: "100%",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={`${process.env.REACT_APP_FILE_BASE_URL}${articleDetails?.data?.journal?.coverImgUrl}`}
                  alt="journal cover"
                  style={{
                    maxWidth: "80%",
                    borderRadius: theme.borderRadius[10],
                  }}
                />
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    fontWeight: 600,
                    marginTop: "20px",
                    textAlign: "center",
                  }}
                >
                  Publisher: International Federation of Research and Discovery
                </Typography>
              </Box>
              <Box>
                <StyledBadgeBox>{`Published Date: ${moment(
                  articleDetails?.data?.publishedDate
                ).format("DD-MM-YYYY")}`}</StyledBadgeBox>
                <StyledBadgeBox>{`${articleDetails?.data?.archive?.name}, ${articleDetails?.data?.issue?.name}`}</StyledBadgeBox>
                {articleDetails?.data?.pdfUrl && (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      "&.MuiButton-root": {
                        color: theme.palette.neutral[100],
                        fontSize: "16px",
                        fontWeight: 400,
                        height: "35px",
                        marginBottom: "10px",
                        borderRadius: "30px",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: theme.palette.primary.hover,
                        },
                      },
                    }}
                    size="small"
                    type="button"
                    onClick={() =>
                      onClickDownload(
                        articleDetails?.data?.pdfUrl,
                        `${articleDetails?.data?.name}.pdf`
                      )
                    }
                  >
                    Download PDF
                  </Button>
                )}
                <CustomMenu
                  menuButtonText={`JATS XML Meta-data`}
                  options={xmlOptions}
                  showMenuArrow
                  buttonProps={{
                    fullWidth: true,
                    variant: "contained",
                    color: "primary",
                    sx: {
                      "&.MuiButton-root": {
                        color: theme.palette.neutral[100],
                        fontSize: "16px",
                        fontWeight: 400,
                        height: "35px",
                        marginBottom: "10px",
                        borderRadius: "30px",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: theme.palette.primary.hover,
                        },
                      },
                    },
                  }}
                  menuProps={{
                    sx: {
                      "& .MuiPaper-root": {
                        width: "23%",
                      },
                    },
                  }}
                />
                {articleDetails?.data?.htmlUrl && (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      "&.MuiButton-root": {
                        color: theme.palette.neutral[100],
                        fontSize: "16px",
                        height: "35px",
                        fontWeight: 400,
                        marginBottom: "10px",
                        borderRadius: "30px",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: theme.palette.primary.hover,
                        },
                      },
                    }}
                    size="small"
                    type="button"
                    onClick={() =>
                      onClickDownload(
                        articleDetails?.data?.htmlUrl,
                        `${articleDetails?.data?.name}.html`
                      )
                    }
                  >
                    Download HTML
                  </Button>
                )}
                {articleDetails?.data?.citeArticle?.length > 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      "&.MuiButton-root": {
                        color: theme.palette.neutral[100],
                        fontSize: "16px",
                        height: "35px",
                        fontWeight: 400,
                        marginBottom: "10px",
                        borderRadius: "30px",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: theme.palette.primary.hover,
                        },
                      },
                    }}
                    size="small"
                    type="button"
                    onClick={() => setOpenCitation(true)}
                  >
                    Cite This Article
                  </Button>
                )}
              </Box>
              <Box
                sx={{
                  borderRadius: "30px",
                  overflow: "hidden",
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    padding: "5px 10px",
                    background: theme.palette.primary.main,
                  }}
                >
                  Copyright Notice
                </Box>
                <Box
                  sx={{
                    padding: "10px 20px",
                    background: theme.palette.neutral[0],
                    textAlign: "justify",
                    "& .MuiTypography-root": {
                      wordWrap: "break-word",
                      fontSize: "12px",
                      marginBottom: "10px",
                      "& a": {
                        color: "#9d6347",
                        textDecoration: "none",
                      },
                    },
                  }}
                >
                  <Typography variant="body1" gutterBottom>
                    This work is licensed under a{" "}
                    <a
                      href={
                        "https://creativecommons.org/licenses/by-nc-sa/4.0/"
                      }
                    >
                      Creative Commons Attribution-NonCommercial-ShareAlike 4.0
                      International License
                    </a>
                    <img
                      src={CopyrightLogo}
                      alt="Copyright logo"
                      width={"70px"}
                      style={{
                        // marginTop: "5px",
                        marginLeft: "5px",
                      }}
                    />
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Authors retain copyright and grant the{" "}
                    <a
                      href={`${window.location.origin}/journal/${articleDetails?.data?.journal?.journalCustomId}`}
                    >
                      {articleDetails?.data?.journal?.name}
                    </a>{" "}
                    right-of-first publication. In addition, the work will be
                    simultaneously licensed under a{" "}
                    <a
                      href={
                        "https://creativecommons.org/licenses/by-nc-sa/4.0/"
                      }
                    >
                      Creative Commons Attribution-NonCommercial-ShareAlike 4.0
                      International License
                    </a>
                    . This license allows others to share the work in whole or
                    part (for non-commercial purpose), with an acknowledgement
                    of the work's authorship and initial publication in{" "}
                    {articleDetails?.data?.journal?.name}.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Furthermore, authors are free to enter into separate
                    contractual arrangements for the non-exclusive distribution
                    of the journal's published version of the work, with an
                    acknowledgement of its initial publication in this journal.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Authors are permitted and encouraged to share their work
                    online or present it at relevant academic, professional, or
                    industry conferences prior to or during the submission
                    process.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={9}>
              <Box
                sx={{
                  marginBottom: "30px",
                }}
              >
                <Box>
                  <Typography
                    variant="caption"
                    gutterBottom
                    sx={{
                      wordWrap: "break-word",
                    }}
                  >
                    {articleDetails?.data?.type}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      wordWrap: "break-word",
                    }}
                  >
                    {articleDetails?.data?.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  {articleDetails?.data?.authors?.map((author, authorIndex) => (
                    <Box
                      sx={{
                        display: "inherit",
                      }}
                      key={`author_${authorIndex}`}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          wordWrap: "break-word",
                          lineHeight: 2,
                        }}
                      >
                        {author?.middleName
                          ? `${authorIndex + 1}. ${author?.firstName} ${
                              author?.middleName
                            } ${author?.lastName}`
                          : `${authorIndex + 1}. ${author?.firstName} ${
                              author?.lastName
                            }`}
                      </Typography>
                      <Box
                        onClick={() =>
                          setOpenAuthorEmail({
                            open: true,
                            authorName: author?.middleName
                              ? `${author?.firstName} ${author?.middleName} ${author?.lastName}`
                              : `${author?.firstName} ${author?.lastName}`,
                            authorEmail: author.email,
                          })
                        }
                        sx={{
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <i class="ri-mail-fill"></i>
                      </Box>
                    </Box>
                  ))}
                </Box>
                <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontWeight: 500,
                    }}
                  >
                    Affiliation
                  </Typography>
                  {articleDetails?.data?.authors?.map((author, authorIndex) => (
                    <Box>
                      <Typography
                        variant="caption"
                        sx={{
                          wordWrap: "break-word",
                          lineHeight: 2,
                        }}
                        key={`author_affiliation_${authorIndex}`}
                      >
                        {`${authorIndex + 1}. ${author?.affiliation}`}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Box>
                  <Typography
                    variant="caption"
                    gutterBottom
                    sx={{
                      wordWrap: "break-word",
                    }}
                  >
                    DOI:{" "}
                    <a href={`https://doi.org/${articleDetails?.data?.doi}`}>
                      {articleDetails?.data?.doi}
                    </a>
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ marginBottom: "30px" }}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  Abstract
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {articleDetails?.data?.articleAbstract}
                </Typography>
              </Box>
              <Box sx={{ marginBottom: "30px" }}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  Keywords
                </Typography>
                {articleDetails?.data?.keywords?.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    {articleDetails?.data?.keywords?.map(
                      (keyword, keywordIndex) => (
                        <Box
                          sx={{
                            display: "inherit",
                            background: theme.palette.neutral[10],
                            p: 1,
                            borderRadius: theme.borderRadius[5],
                          }}
                          key={`keyword_${keywordIndex}`}
                        >
                          <Typography variant="body2" gutterBottom>
                            {keyword?.name}
                          </Typography>
                        </Box>
                      )
                    )}
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  "& > *": {
                    margin: 0,
                  },
                  marginBottom: "30px",
                }}
              >
                {htmlParser(
                  DOMPurify.sanitize(articleDetails?.data?.articleBody),
                  {
                    replace: (domNode, index) => {
                      if (
                        domNode.type === "tag" &&
                        domNode.name === "p" &&
                        domNode.children.length <= 0
                      ) {
                        return <br />;
                      }
                    },
                  }
                )}
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  REFERENCES
                </Typography>
                <ol>
                  {articleDetails?.data?.references?.map(
                    (reference, referenceIndex) => (
                      <li>
                        <Typography
                          variant="body2"
                          gutterBottom
                          key={`reference_${referenceIndex}`}
                        >
                          {`${formatReferences(reference)}`}
                        </Typography>
                      </li>
                    )
                  )}
                </ol>
              </Box>
            </Grid>
          </Grid>
          <CustomDialog
            open={openCitation}
            title={
              <Box>
                <Box
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    “Cite this article”
                  </Typography>
                </Box>
                <Box
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <Typography variant="caption">
                    <strong>Note:</strong> To cite this article, copy any
                    standard format mentioned below and paste it on your
                    research work
                  </Typography>
                </Box>
              </Box>
            }
            agreeButtonText={"Close"}
            agreeButtonProp={{
              variant: "contained",
              color: "secondary",
              type: "button",
              onClick: () => setOpenCitation(false),
              size: "small",
              sx: {
                "&.MuiButton-root": {
                  color: theme.palette.neutral[0],
                  // width: "100%",
                  borderRadius: theme.borderRadius[10],
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.hover,
                  },
                },
              },
            }}
            cancelButtonProp={{ sx: { display: "none" } }}
            dialogProp={{
              maxWidth: "md",
              sx: {
                "& .MuiDialogTitle-root": {
                  background: theme.palette.primary.litSurface,
                },
              },
            }}
          >
            <Grid
              container
              sx={{
                marginTop: "10px",
              }}
            >
              {articleDetails?.data?.citeArticle?.map(
                (cite, citeIndex) =>
                  cite?.citeDescription && (
                    <Grid
                      item
                      container
                      spacing={2}
                      xs={12}
                      sx={{
                        marginBottom: "10px",
                      }}
                    >
                      <Grid
                        key={`${citeIndex}_0_${cite.name}`}
                        item
                        xs={12}
                        sm={3}
                        md={3}
                        lg={3}
                      >
                        <Typography variant="body2" gutterBottom>
                          {cite.name}
                        </Typography>
                      </Grid>
                      <Grid
                        key={`${citeIndex}_1_${cite.name}`}
                        item
                        xs={12}
                        sm={9}
                        md={9}
                        lg={9}
                        sx={{
                          borderBottom: `1px solid ${theme.palette.neutral[20]}`,
                        }}
                      >
                        <Typography variant="body2" gutterBottom>
                          {cite.citeDescription}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Box
                            sx={{
                              padding: "2px 10px",
                              borderRadius: "30px",
                              background: theme.palette.neutral[40],
                              color: theme.palette.neutral[0],
                              marginBottom: "10px",
                              textAlign: "center",
                              "&:hover": {
                                background: theme.palette.neutral[70],
                                cursor: "pointer",
                              },
                            }}
                            onClick={() => {
                              navigator.clipboard.writeText(
                                cite.citeDescription
                              );
                              toast.success("Cite copied");
                            }}
                          >
                            <i className="ri-file-copy-fill"></i>{" "}
                            <Typography variant="caption" gutterBottom>
                              Click here to copy
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  )
              )}
            </Grid>
          </CustomDialog>
          <CustomDialog
            open={openAuthorEmail.open}
            agreeButtonText={"Close"}
            agreeButtonProp={{
              variant: "contained",
              color: "secondary",
              type: "button",
              onClick: () =>
                setOpenAuthorEmail({
                  open: false,
                  authorName: "",
                  authorEmail: "",
                }),
              size: "small",
              sx: {
                "&.MuiButton-root": {
                  color: theme.palette.neutral[0],
                  // width: "100%",
                  borderRadius: theme.borderRadius[10],
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.hover,
                  },
                },
              },
            }}
            cancelButtonProp={{ sx: { display: "none" } }}
            dialogProp={{
              maxWidth: "md",
              sx: {
                "& .MuiDialogTitle-root": {
                  background: theme.palette.primary.litSurface,
                },
              },
            }}
          >
            <Grid
              container
              sx={{
                marginTop: "10px",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  borderBottom: `1px solid ${theme.palette.neutral[20]}`,
                }}
              >
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{
                    userSelect: "none",
                  }}
                >
                  {openAuthorEmail.authorName}:{" "}
                  <strong>{openAuthorEmail.authorEmail}</strong>
                </Typography>
              </Grid>
            </Grid>
          </CustomDialog>
        </>
      )}
    </Box>
  );
};

export default ArticleView;
