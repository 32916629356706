import { jsx } from "slate-hyperscript";

function extractStylesToJSX(styleString) {
  if (!styleString) return;
  const styles = {};
  const styleArray = styleString.split(";");

  styleArray.forEach((style) => {
    const [key, value] = style.trim().split(":");
    let cssKey = key.replace(/^mso-/, ""); // remove "mso-" prefix
    cssKey = cssKey.replace(/^ms-/, ""); // remove "ms-" prefix

    if (cssKey.startsWith("mso") || cssKey.startsWith("ms")) {
      cssKey = cssKey.replace(/-/g, ""); // remove hyphens only for MS Word CSS properties
    }

    cssKey = cssKey.replace(/([A-Z])/g, (match) => `-${match.toLowerCase()}`); // add hyphens and lowercase
    cssKey = cssKey.replace(/-/g, (match) => match.toUpperCase()); // camelCase

    if (CSS.supports(cssKey, value)) {
      styles[cssKey] = value.trim();
    }
  });

  // Ensure border style is applied correctly
  if (styles.border) {
    styles.borderStyle = styles.border;
    styles.borderWidth = styles.border;
    styles.borderColor = styles.border;
  }

  if (
    styles.backgroundColor === "white" ||
    styles.backgroundColor === "#ffffff" ||
    styles.backgroundColor === "#FFF"
  ) {
    delete styles.backgroundColor;
  }

  if (
    styles.background === "white" ||
    styles.background === "#ffffff" ||
    styles.background === "#FFF"
  ) {
    delete styles.background;
  }

  return styles;
}

const deserializeHtmlToSlate = (el, markAttributes = {}) => {
  if (el.nodeType === Node.TEXT_NODE) {
    if (el.parentNode.nodeName === "O:P") {
      if (el.parentNode.parentNode.nodeName === "P") {
        const pStyle = el.parentNode.getAttribute("style");
        return jsx("text", { pStyle, ...markAttributes }, el.textContent);
      }
    }

    if (el.textContent && el.textContent?.trim() === "") {
      // check if text is empty or only whitespace
      return null;
    } else {
      // sometimes work adds line breaks when pasting
      const regex = /\n(?!\n)/g;
      el.textContent = el.textContent.replace(regex, " ");
      // return el.textContent
      return jsx("text", markAttributes, el.textContent);
    }
  } else if (el.nodeType === Node.ELEMENT_NODE) {
    // set div tag style to img element
    if (el.nodeName === "IMG") {
      if (el.parentNode.nodeName === "DIV") {
        const imgDivStyle = el.parentNode.getAttribute("style");
        el.style = imgDivStyle;
      }
    }
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = { ...markAttributes };
  const elAttributes = {};
  let elAlignment = "";

  // Get the attribute names for the HTML element
  const attributeNames = Array.from(el.getAttributeNames());

  // Iterate over the attribute names and add their values to elAttributes
  attributeNames.forEach((attributeName) => {
    if (
      attributeName !== "style" &&
      attributeName !== "class" &&
      attributeName !== "className" &&
      attributeName !== "src"
    ) {
      const attributeValue = el.getAttribute(attributeName);

      elAttributes[attributeName] = attributeValue;
    } else if (attributeName === "style") {
      // element style attribute
      const styleAttributeValue = extractStylesToJSX(
        el.getAttribute(attributeName)
      );

      if (styleAttributeValue?.["text-align"]) {
        elAlignment = styleAttributeValue["text-align"];
        delete styleAttributeValue["text-align"];
      }

      elAttributes[attributeName] = styleAttributeValue;
    }
  });

  // define attributes for text nodes
  switch (el.nodeName) {
    case "CODE":
      nodeAttributes.code = true;
      break;
    case "DEL":
      nodeAttributes.strikethrough = true;
      break;
    case "EM":
      nodeAttributes.italic = true;
      break;
    case "I":
      nodeAttributes.italic = true;
      break;
    case "S":
      nodeAttributes.strikethrough = true;
      break;
    case "STRONG":
      nodeAttributes.bold = true;
      break;
    case "B":
      nodeAttributes.bold = true;
      break;
    case "U":
      nodeAttributes.underline = true;
      break;
    case "SUP":
      nodeAttributes.superscript = true;
      break;
    case "SUB":
      nodeAttributes.subscript = true;
      break;
    default:
      break;
  }

  const children = Array.from(el.childNodes)
    .map((node) => deserializeHtmlToSlate(node, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push(jsx("text", nodeAttributes, ""));
  }

  let finalAttributes = {
    elAttributes: { ...elAttributes },
    ...(elAlignment && { align: elAlignment }),
  };

  switch (el.nodeName) {
    case "BODY":
      return jsx("fragment", {}, children);
    case "BR":
      return " ";
    case "BLOCKQUOTE":
      return jsx("element", { type: "quote", ...finalAttributes }, children);
    case "P":
      return jsx(
        "element",
        { type: "paragraph", ...finalAttributes },
        children
      );
    case "O:P":
      return jsx("element", { type: "paragraph" }, children);
    case "H1":
      return jsx(
        "element",
        { type: "heading-one", ...finalAttributes },
        children
      );
    case "H2":
      return jsx(
        "element",
        { type: "heading-two", ...finalAttributes },
        children
      );
    case "H3":
      return jsx(
        "element",
        { type: "heading-three", ...finalAttributes },
        children
      );
    case "H4":
      return jsx(
        "element",
        { type: "heading-four", ...finalAttributes },
        children
      );
    case "H5":
      return jsx(
        "element",
        { type: "heading-five", ...finalAttributes },
        children
      );
    case "H6":
      return jsx(
        "element",
        { type: "heading-six", ...finalAttributes },
        children
      );
    case "UL":
      return jsx(
        "element",
        { type: "bulleted-list", ...finalAttributes },
        children
      );
    case "LI":
      return jsx(
        "element",
        { type: "list-item", ...finalAttributes },
        children
      );
    case "OL":
      return jsx(
        "element",
        { type: "numbered-list", ...finalAttributes },
        children
      );
    case "TABLE":
      return jsx("element", { type: "table", ...finalAttributes }, children);
    case "TBODY":
      return jsx("element", { type: "tbody", ...finalAttributes }, children);
    case "TR":
      return jsx(
        "element",
        { type: "table-row", ...finalAttributes },
        children
      );
    case "TD":
      return jsx(
        "element",
        { type: "table-cell", ...finalAttributes },
        children
      );
    case "IMG":
      return jsx(
        "element",
        { type: "image", url: el.getAttribute("src"), ...finalAttributes },
        children
      );
    case "A":
      const href = el.getAttribute("href");
      if (!href) {
        // If href is missing, return children directly
        return children;
      }
      return jsx(
        "element",
        { type: "link", url: href, ...finalAttributes },
        children
      );
    case "PRE":
      return jsx("element", { type: "code", ...finalAttributes }, children);

    default:
      return children;
  }
};

export default deserializeHtmlToSlate;
